// Labels (not the form kind)

.label,
.label-blue {
  display: inline-block;
  padding: 0.16em 0.56em;
  margin-right: $sp-2;
  margin-left: $sp-2;
  color: $white;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: $blue-100;
  @include fs-2;

  border-radius: 12px;
}

.label-green {
  background-color: $green-200;
}

.label-purple {
  background-color: $purple-100;
}

.label-red {
  background-color: $red-200;
}

.label-yellow {
  color: $grey-dk-200;
  background-color: $yellow-200;
}
